<template>
    <router-link :to="info.path" class="service-box">
        <div class="service-top">
            <img :src="require(`../../assets/${info.icon}.png`)" alt="" />
        </div>
        <div class="service-text">
            <div class="text2">{{ info.name }}</div>
        </div>

        <!-- <div class="service-hover">
            <div class="top">
                <div class="text">
                    <div>0{{ index + 1 }}.</div>
                    <div>{{ info.name }}</div>
                </div>
                <img :src="require(`../../assets/svgs/${info.icon}-02.svg`)" alt="" />
            </div>
            <div class="content">
                {{ info.content }}
            </div>
            <i class="iconfont iconfont-next"></i>
        </div> -->
    </router-link>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        index: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="less" scoped>
.service-box {
    display: block;
    width: 208px;
    height: 208px;
    border-radius: 1px;
    box-shadow: 0px 2px 10px 0px #f5f7fa, inset 2px 2px 4px 0px #f5f7fa;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    color: #000000;
    // &::after {
    //     content: '';
    //     height: 4px;
    //     background: @prim;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     z-index: 2;
    // }

    &:hover {
        box-sizing: border-box;
        border: 1px solid #01a041;
        color: #01a041;
    }
}
.service-top {
    padding-top: 48px;
    box-sizing: border-box;
    .flex();
    justify-content: center;
    .text {
        // font-size: 16px;
        // font-weight: bold;
        // color: @prim;
        // line-height: 24px;

        // div {
        //     &:first-child {
        //         font-size: 25px;
        //         font-family: Impact;
        //         padding-bottom: 15px;
        //     }
        // }
    }
    img {
        width: 76px;
        height: 76px;
        display: block;
        margin-bottom: 22px;
    }
}
.service-text {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 24px;
    .flex();
    justify-content: center;
    // color: #ff8700;
    // line-height: 24px;
    // .flex();
    // padding: 46px 0 64px;
    // justify-content: center;
    // .text2 {
    //     font-size: 20px;
    //     font-weight: bold;
    // }

    // .text1 {
    //     font-family: Impact;
    //     font-size: 22px;
    // }
}
.service-content {
    font-size: 14px;
    color: #515151;
    line-height: 24px;
    padding: 24px 16px 16px;
    min-height: 130px;
    box-sizing: border-box;
}

.service-hover {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background-color: #008a69;
    color: #fff;
    z-index: 3;
    padding: 16px;
    height: 100%;
    transition: top ease-in-out 0.3s;
    box-sizing: border-box;
    .top {
        height: 133px;
        .flex();
        justify-content: space-between;
        box-sizing: border-box;
        .text {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;

            div {
                &:first-child {
                    font-size: 25px;
                    font-family: Impact;
                    padding-bottom: 15px;
                }
            }
        }
        img {
            width: 70px;
            height: 70px;
            display: block;
            margin-left: 10px;
        }
    }
    .content {
        font-size: 14px;
        line-height: 24px;
        padding-top: 20px;
        border-top: 1px solid #ffffff;
    }
    .iconfont {
        font-size: 16px;
        position: absolute;
        right: 16px;
        bottom: 16px;
    }
}
</style>
