<template>
    <div ref="title" class="page-title" @click="goTo">
        <div class="title">
            <span v-if="hasPre">
                <slot></slot>
            </span>
            <span v-if="hasPres">
                <slot> 平台</slot>
            </span>
            <span v-if="development">
                <slot> 发展</slot>
            </span>
            <span v-if="honor">
                <slot> 资质</slot>
            </span>
            <span v-if="We">
                <slot> 联系</slot>
            </span>
            <span><slot name="prim"></slot></span>
        </div>

        <div class="sub">
            <slot name="sub">Convergence service</slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DividerTitle',
    props: {
        hasPre: {
            type: Boolean,
            default: true
        },
        hasPres: {
            type: Boolean,
            default: false
        },
        We: {
            type: Boolean,
            default: false
        },
        honor: {
            type: Boolean,
            default: false
        },
        development: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        goTo() {
            window.scrollTo({
                top: this.$refs.title.offsetTop - 100,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style lang="less" scoped>
.title {
    font-size: 22px;
    font-weight: bold;
    display: flex;
    color: @warn;
    line-height: 32px;
    font-family: Noto Sans Bold;
    margin-left: 10px;
    span {
        vertical-align: middle;
        line-height: 32px;
        &:last-child {
            font-size: 22px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #313233;
            margin-bottom: 10px;
        }

        img {
            display: inline-block;
        }
    }
}

.page-title {
    // margin: auto;
    padding: 0px 50px 40px;
    padding-left: 0px;
    // .flex-col();
    align-items: center;
    cursor: pointer;

    &:hover {
        .sub {
            font-size: 12px;
            font-family: Arial-Black, Arial;
            font-weight: bold;
            color: #bcc1cc;
            &::before,
            &::after {
                width: 45px;
            }
        }
    }
}

.sub {
    font-size: 12px;
    font-weight: bolder;
    color: #BCC1CC;
    line-height: 17px;
    font-family: Arial-Black, Arial;
    .flex();
    margin-top: 3px;
    text-transform: uppercase;
    &::before,
    &::after {
        content: '';
        width: 30px;
        height: 1px;
        background: @info;
        margin: 0 10px;
        transition: width ease-in-out 0.3s;
    }
}
</style>
