<template>
    <div class="header-btn" :class="{ active: isChoose }" @mouseenter="choose">
        <div class="icon">
            <i :class="'iconfont ' + icon"></i>
        </div>
        <div class="text">{{ name }}</div>
    </div>
</template>

<script>
export default {
    name: 'HeaderBtn',
    props: {
        icon: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        isChoose: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        choose() {
            this.$emit('choose');
        }
    }
};
</script>

<style lang="less" scoped>
.header-btn {
    .flex-col();
    align-items: center;
    cursor: pointer;
    min-width: 90px;

    .icon {
        width: 90px;
        height: 90px;
        background: #ffffff;
        border-radius: 45px;
        border: 1px solid #dcdfe6;
        text-align: center;
        .iconfont {
            line-height: 92px;
            font-size: 48px;
            color: #878d99;
        }
    }

    .text {
        font-size: 14px;
        font-weight: 400;
        color: #565b66;
        line-height: 26px;
        margin-top: 16px;
    }
    &:hover {
        .icon {
            background-color: @prim;
            border-color: @prim;
            .iconfont {
                color: #fff;
            }
        }

        .text {
            color: @prim;
            font-weight: bold;
        }
    }

    &.active {
        .icon {
            background-color: @prim;
            border-color: @prim;
            .iconfont {
                color: #fff;
            }
        }

        .text {
            color: @prim;
            font-weight: bold;
        }
    }
}
</style>
