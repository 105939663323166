<template>
    <div class="resources">
        <div class="resources-header">
            <swiper class="resources-swiper" ref="swiper" :options="resourceOptions">
                <swiper-slide v-for="(item, index) in resources" :key="index">
                    <header-btn
                        :isChoose="chooseResource === index"
                        :key="index"
                        :icon="item.icon"
                        :name="item.name"
                        ref="btn"
                        @choose="changeResource(index)"
                    ></header-btn>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
            </swiper>

            <div class="resources-tag" :style="{ left: `${left}px` }"></div>
        </div>

        <div class="resource-box">
            <div class="news-content" v-loading="loading">
                <div class="top">
                    <div class="title">{{ resource.name }}</div>
                    <router-link :to="resource.path">
                        <el-link :underline="false" type="primary" class="more">
                            更多<i class="el-icon-view el-icon-arrow-right"></i>
                        </el-link>
                    </router-link>
                </div>
                <div class="news-list">
                    <div v-if="chooseResource !== 6">
                        <router-link
                            v-for="item in list"
                            :key="item.id"
                            :to="{
                                path: resource.detailPath,
                                query: {
                                    id: item.id,
                                    fromHome: true
                                }
                            }"
                            class="news-link"
                        >
                            <span class="text1">{{ item.name }}</span>
                            <span class="text2">{{ formatTime(item.createdAt || item.applyTime, 'MM.DD') }}</span>
                        </router-link>
                        <div class="empty" v-if="empty">
                            <i class="el-icon-takeaway-box"></i>
                            <div class="empty-text">暂无数据</div>
                        </div>
                    </div>

                    <div v-else>
                        <expert-gride
                            v-for="item in list"
                            fromHome
                            :key="item.id"
                            :info="item"
                            size="mini"
                        ></expert-gride>
                    </div>
                </div>
            </div>

            <div class="imgList" v-if="hotImgList.length > 0">
                <router-link
                    :to="{
                        path: resource.detailPath,
                        query: {
                            id: item.id,
                            fromHome: true
                        }
                    }"
                    class="img-item"
                    v-for="item in hotImgList"
                    :key="item.id"
                >
                    <img :src="getImg(item.img)" object-fit="cover" alt="" />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderBtn from './HeaderBtn.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ExpertGride from '../list/ExpertGride.vue';

export default {
    components: { HeaderBtn, Swiper, SwiperSlide, ExpertGride },
    data() {
        return {
            chooseResource: '',
            resources: [
                {
                    icon: 'iconfont-imt_fuwu_icon_01_pre',
                    name: '政策',
                    url: '/policyLaw/all',
                    defaultQuery: {
                        category: 'POLICY'
                    },
                    path: '/policy',
                    detailPath: '/legalDetail'
                },
                {
                    icon: 'iconfont-imt_fuwu_icon_02',
                    name: '法律',
                    url: '/policyLaw/all',
                    defaultQuery: {
                        category: 'LAW'
                    },
                    path: '/legal',
                    detailPath: '/legalDetail'
                },
                {
                    icon: 'iconfont-imt_fuwu_icon_04_pre',
                    name: '知产',
                    url: '/intellectualProperty/all',
                    defaultQuery: {
                        category: 'POLICY'
                    },
                    path: '/intellectualProperty',
                    detailPath: '/intellectualPropertyDetail'
                },
                {
                    icon: 'iconfont-imt_fuwu_icon_03',
                    name: '技术',
                    url: '/digital/all',
                    defaultQuery: {
                        type: 'TECHNOLOGY'
                    },
                    path: '/digital?type=TECHNOLOGY',
                    detailPath: '/digitalDetail'
                },
                {
                    icon: 'iconfont-imt_fuwu_icon_042',
                    name: '产品',
                    url: '/digital/all',
                    defaultQuery: {
                        type: 'PRODUCT'
                    },
                    path: '/digital?type=PRODUCT',
                    detailPath: '/digitalDetail'
                },
                {
                    icon: 'iconfont-imt_fuwu_icon_04',
                    name: '企业',
                    url: '/company/all',
                    defaultQuery: {
                        type: 'COMPANY'
                    },
                    path: '/digital?type=COMPANY',
                    detailPath: '/digitalDetails'
                },
                {
                    icon: 'iconfont-imt_fuwu_icon_041',
                    name: '专家',
                    url: '/expert/all',
                    defaultQuery: {
                        isExpert: true
                    },
                    path: '/expert',
                    detailPath: '/expert/all'
                }
            ],
            resourceOptions: {
                slidesPerView: 5,
                spaceBetween: 100,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            left: 175,
            list: [],
            empty: false,
            loading: false
        };
    },
    computed: {
        swiper() {
            return this.$refs.swiper.$swiper;
        },
        resource() {
            return [...this.resources][this.chooseResource || 0];
        },
        hotImgList() {
            let imgs = [];
            [...this.list].forEach(item => {
                console.log(item);
                if (item.img && imgs.length < 3) {
                    imgs.push(item);
                }
            });
            return imgs;
        }
    },
    mounted() {
        this.changeResource(0);
    },
    methods: {
        changeResource(index) {
            if (this.chooseResource === index) {
                return;
            }
            this.chooseResource = index;
            let resource = [...this.resources][index];
            this.list = [];
            this.empty = false;
            this.loading = true;
            this.$nextTick(() => {
                this.left = this.swiper.translate + 214 + this.$refs.btn[this.chooseResource].$el.parentNode.offsetLeft;

                this.$http
                    .post(
                        resource.url,
                        {
                            size: 20,
                            page: 0,
                            sort: 'createdAt,desc',
                            query: {
                                ...resource.defaultQuery
                            }
                        },
                        {
                            body: 'json'
                        }
                    )
                    .then(res => {
                        this.list = res.content;
                        // console.log(this.list);
                        this.loading = false;
                        this.empty = res.empty;
                    });
            });
        }
    }
};
</script>

<style lang="less" scoped>
.resources-header {
    .flex();
    padding: 40px 0 24px;
    justify-content: space-between;
    .header-btn {
        // padding: 0 40px;
    }

    position: relative;
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0f264d;
        height: 1px;
        z-index: 1;
    }
    .resources-tag {
        position: absolute;
        bottom: 0px;
        width: 10px;
        height: 6px;
        background-color: #fff;
        z-index: 2;
        transition: left ease-in-out 0.3s;
        &::before {
            content: '';
            background-color: #0f264d;
            width: 8px;
            left: -2px;
            height: 1px;
            position: absolute;
            bottom: 0;
            transform-origin: 0px 0px;
            transform: rotate(-30deg);
        }
        &::after {
            content: '';
            background-color: #0f264d;
            width: 8px;
            right: -2px;
            height: 1px;
            position: absolute;
            bottom: 0;
            transform-origin: 8px 0px;
            transform: rotate(30deg);
        }
    }
}
.resources-swiper {
    margin: 0 auto;
    width: 850px;
    padding: 0 70px;
}
.resource-box {
    padding: 30px 0 100px;
    .flex();
}
.news-content {
    padding: 10px 30px;
    flex-grow: 1;
    height: 383px;
    box-shadow: 0px 2px 10px 0px rgba(25, 25, 25, 0.1);
    box-sizing: border-box;
    .flex-col();
    .top {
        .flex();
        .title {
            color: @prim;
            font-size: 16px;
            font-weight: bold;
        }
        padding: 20px 0;
        justify-content: space-between;
        border-bottom: 1px solid @bg;
    }
}

.news-list {
    flex-grow: 1;
    overflow: auto;
    padding: 25px 12px 15px;
    .news-link {
        text-decoration: none;
        .flex();
        font-size: 14px;
        line-height: 20px;

        .text1 {
            color: #000;
            flex-grow: 1;
        }

        .text2 {
            color: @info;
        }

        &:hover {
            .text1 {
                color: @prim;
            }

            .text2 {
                color: fade(@prim, 60%);
            }
        }
    }

    .news-link + .news-link {
        margin-top: 25px;
    }
}

.swiper-button-prev {
    left: 0;
    width: calc(35px / 44 * 27);
    height: 35px;
    font-size: 35px;
}
.swiper-button-next {
    right: 0;
    width: calc(35px / 44 * 27);
    height: 35px;
    font-size: 35px;
}

.empty {
    padding: 50px 0;
    .flex-col();
    align-items: center;
    i {
        font-size: 50px;
        color: #939599;
    }

    .empty-text {
        font-size: 14px;
        color: #939599;
        margin-top: 20px;
    }
}

.imgList {
    width: 150px;
    margin-left: 30px;
    height: 383px;
    .img-item {
        cursor: pointer;
        height: 113px;
        overflow: hidden;
        display: block;
        img {
            width: 100%;
            height: 100%;
            display: block;
            transition: transform ease-in-out 0.3s;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
</style>
