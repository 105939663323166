var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resources"},[_c('div',{staticClass:"resources-header"},[_c('swiper',{ref:"swiper",staticClass:"resources-swiper",attrs:{"options":_vm.resourceOptions}},[_vm._l((_vm.resources),function(item,index){return _c('swiper-slide',{key:index},[_c('header-btn',{key:index,ref:"btn",refInFor:true,attrs:{"isChoose":_vm.chooseResource === index,"icon":item.icon,"name":item.name},on:{"choose":function($event){return _vm.changeResource(index)}}})],1)}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2),_c('div',{staticClass:"resources-tag",style:({ left: `${_vm.left}px` })})],1),_c('div',{staticClass:"resource-box"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"news-content"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.resource.name))]),_c('router-link',{attrs:{"to":_vm.resource.path}},[_c('el-link',{staticClass:"more",attrs:{"underline":false,"type":"primary"}},[_vm._v(" 更多"),_c('i',{staticClass:"el-icon-view el-icon-arrow-right"})])],1)],1),_c('div',{staticClass:"news-list"},[(_vm.chooseResource !== 6)?_c('div',[_vm._l((_vm.list),function(item){return _c('router-link',{key:item.id,staticClass:"news-link",attrs:{"to":{
                            path: _vm.resource.detailPath,
                            query: {
                                id: item.id,
                                fromHome: true
                            }
                        }}},[_c('span',{staticClass:"text1"},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"text2"},[_vm._v(_vm._s(_vm.formatTime(item.createdAt || item.applyTime, 'MM.DD')))])])}),(_vm.empty)?_c('div',{staticClass:"empty"},[_c('i',{staticClass:"el-icon-takeaway-box"}),_c('div',{staticClass:"empty-text"},[_vm._v("暂无数据")])]):_vm._e()],2):_c('div',_vm._l((_vm.list),function(item){return _c('expert-gride',{key:item.id,attrs:{"fromHome":"","info":item,"size":"mini"}})}),1)])]),(_vm.hotImgList.length > 0)?_c('div',{staticClass:"imgList"},_vm._l((_vm.hotImgList),function(item){return _c('router-link',{key:item.id,staticClass:"img-item",attrs:{"to":{
                    path: _vm.resource.detailPath,
                    query: {
                        id: item.id,
                        fromHome: true
                    }
                }}},[_c('img',{attrs:{"src":_vm.getImg(item.img),"object-fit":"cover","alt":""}})])}),1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }