<template>
    <div class="home">
        <banner></banner>
        <div class="center-content" style="padding-top: 79px">
            <divider-title>
                <template #prim>知产咨询动态</template>
                <template #sub>Information trends</template>
            </divider-title>
            <div class="news">
                <div class="carousel news-item" @mouseover="autoplay = false" @mouseout="autoplay = true">
                    <el-carousel ref="carousel" height="360px" arrow="never" trigger="hover" @change="changeCarousl">
                        <el-carousel-item v-for="(item, index) in newsListIndex" :key="index">
                            <el-image
                                :src="item.img"
                                @click="moreQualificationsOne"
                                style="width: 100%; height: 100%; cursor: pointer"
                                fit="cover"
                            ></el-image>

                            <p class="swiper-itemText">{{ item.name }}</p>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="indicators">
                        <span
                            v-for="(item, index) in newsListIndex"
                            @mouseover="changeIndex(index)"
                            :key="index"
                            :class="{ active: activeIndex === index }"
                            >{{ index + 1 }}</span
                        >
                    </div>
                </div>

                <div class="tab news-item">
                    <el-link :underline="false" class="more" @click="moreQualifications">
                        更多<i class="el-icon-view el-icon-arrow-right"></i>
                    </el-link>
                    <div class="tabBox">
                        <div
                            v-for="(item, index) in tabs"
                            @mouseover="handleClick(item)"
                            :key="index"
                            :class="{ active: item === active }"
                            class="tabs"
                        >
                            {{ item }}
                        </div>
                    </div>
                    <div class="news-list" v-if="newsList">
                        <router-link
                            v-for="i in newsList"
                            :key="i.id"
                            :to="{ name: 'newsDetail', query: { id: i.id, fromHome: true } }"
                            class="news-link"
                        >
                            <span class="text1">{{ i.name }}</span>
                            <span class="text2">{{ formatTime(i.issuedAt, 'YYYY.MM.DD') }}</span>
                        </router-link>
                    </div>
                    <div class="empty" v-else>
                        <i class="el-icon-takeaway-box" style="margin-left: 24px"></i>
                        <div style="font-size: 20px">暂无数据</div>
                    </div>
                </div>
            </div>
            <!-- <div class="hot">
                <div class="hot-btn">
                    <div class="btn-item">
                        <el-button icon="el-icon-edit" type="primary" @click="show = true">供给发布</el-button>
                        <el-button icon="el-icon-edit" type="primary" @click="shows = true">需求发布</el-button>
                    </div>
                    <div class="btn-item">
                        <el-button type="primary" @click="connectKefu">
                            <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                            咨询客服
                        </el-button>
                        <el-button @click="postMessage" type="primary"
                            ><i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言</el-button
                        >
                    </div>
                </div>

                <div class="products">
                    <router-link to="/patent?caseType=DECLASSIFICATION" class="product-col2">
                        <div class="text">
                            <div class="text1">供给侧</div>
                            <div class="text2">
                                征集、发布脱密国防专利、专利、商标、版权、软著、技术、产品等卖方信息。
                            </div>
                            <div class="text2">丰富的行业交流、政企交流活动</div>
                        </div>
                        <img src="../assets/svgs/shouye-gongjice-png.svg" alt="" />
                    </router-link>
                    <router-link to="/patentDemand?caseType=DECLASSIFICATION" class="product-col2">
                        <div class="text">
                            <div class="text1">需求侧</div>
                            <div class="text2">
                                征集、发布脱密国防专利、专利、商标、版权、软著、技术、产品等买方信息。
                            </div>
                            <div class="text2">丰富的行业交流、政企交流活动</div>
                        </div>
                        <img src="../assets/svgs/shouye-xuqiucee-png.svg" alt="" />
                    </router-link>

                    <router-link to="/technicalManager" class="product-col3">
                        <img src="../assets/svgs/shouye-jishujingliren-png.svg" alt="" />
                        <div class="text">
                            <div class="text1">技术经理人</div>
                            <div class="text2">为买卖双方推荐与交易标的相匹配的所属领域技术经理人。</div>
                        </div>
                    </router-link>
                    <router-link to="/successCases?category=DECLASSIFICATION" class="product-col3">
                        <img src="../assets/shouye-chengjiaoanli-png.png" alt="" />
                        <div class="text">
                            <div class="text1">成交案例</div>
                            <div class="text2">展示通过平台撮合成功的交易信息。</div>
                        </div>
                    </router-link>
                    <router-link to="/purchase?purchaseItem=ENGINEERING" class="product-col3">
                        <img src="../assets/svgs/shouye-juncaixinxi-png.svg" alt="" />
                        <div class="text">
                            <div class="text1">军采信息</div>
                            <div class="text2">为民参军企业提供工程、货物、服务等项目的军方采购信息。</div>
                        </div>
                    </router-link>
                </div>
            </div> -->
        </div>
        <!-- <a
            href="http://ggfw.cnipa.gov.cn:8010/PatentCMS_Center"
            target="_blank"
            class="ad"
            :style="{ backgroundImage: `url(${require('../assets/jpg-bg.jpg')})` }"
        ></a> -->
        <div class="center-content">
            <divider-title>
                <template #prim>知产转化数据</template>
                <template #sub>IP Conversion data</template>
            </divider-title>
            <div class="center-content_con">
                <!-- <div class="center-content_con_left">
                    <div class="tab news-item" style="height: 383px">
                        <el-link :underline="false" class="more" @click="moreQualificationsTwo">
                            更多<i class="el-icon-view el-icon-arrow-right"></i>
                        </el-link>
                        <div class="tabBox">
                            <div
                                v-for="(item, index) in tabss"
                                @mouseover="handleClicks(item)"
                                :key="index"
                                :class="{ active: item === actives }"
                                class="tabs"
                            >
                                {{ item }}
                            </div>
                        </div>
                        <div class="news-list" v-if="newsLists.length > 0">
                            <div v-for="i in newsLists" :key="i.id" class="news-link" @click="toDetails(i.id)">
                                <div class="news-link_con" v-if="actives === '知识产权'">
                                    <el-image :src="getImg(i.img)" fit="cover" class="news-link_img"></el-image>
                                    <div class="text4">{{ i.name }}</div>
                                </div>
                                <div class="text2" v-if="actives === '知识产权'">{{ i.createdAt }}</div>
                                <div class="news-link_con" v-if="actives === '技术'">
                                    <el-image :src="getImg(i.img)" fit="cover" class="news-link_img"></el-image>
                                    <div class="text4">{{ i.name }}</div>
                                </div>
                                <div class="text2" v-if="actives === '技术'">{{ i.createdAt }}</div>
                                <div class="news-link_con" v-if="actives === '产品'">
                                    <el-image :src="getImg(i.img)" fit="cover" class="news-link_img"></el-image>
                                    <div class="text4">{{ i.name }}</div>
                                </div>
                                <div class="text2" v-if="actives === '产品'">{{ i.createdAt }}</div>
                                <div class="news-link_con" v-if="actives === '企业'">
                                    <el-image :src="getImg(i.img)" fit="cover" class="news-link_img"></el-image>
                                    <div class="text4">{{ i.name }}</div>
                                </div>
                                <div class="text2" v-if="actives === '企业'">{{ i.createdAt }}</div>
                                <div class="news-link_con" v-if="actives === '专家'">
                                    <el-image :src="getImg(i.avatar)" fit="cover" class="news-link_img"></el-image>
                                    <div class="text4">{{ i.name }}</div>
                                </div>
                                <div class="text2" v-if="actives === '专家'">{{ i.createdAt }}</div>
                            </div>
                        </div>
                        <div class="empty" v-else>
                            <i class="el-icon-takeaway-box" style="margin-left: 24px"></i>
                            <div style="font-size: 20px">暂无数据</div>
                        </div>
                    </div>
                </div> -->
                <div class="center-content_con_right">
                    <div class="center-content_con_right_top">
                        <div class="center-content_con_right_top_content" @click="show = true">
                            <img
                                src="../assets/home_icon_gongjifabu@3x (1).png"
                                alt=""
                                class="center-content_con_right_top_content_img"
                            />
                            <div class="center-content_con_right_top_content_title">供给发布</div>
                        </div>
                        <!-- <div class="center-content_con_right_top_content" @click="shows = true">
                            <img
                                src="../assets/home_icon_xuqiufabu@3x (2).png"
                                alt=""
                                class="center-content_con_right_top_content_img"
                            />
                            <div class="center-content_con_right_top_content_title">需求发布</div>
                        </div> -->
                    </div>
                    <div class="center-content_con_right_list">
                        <div
                            class="center-content_con_right_list_con"
                            v-for="item in supplyPatent"
                            :key="item.id"
                            @click="supplyPatents(item.id)"
                        >
                            <!-- <div style="margin-right: 20px">{{ formatTime(item.createdAt, 'YYYY.MM.DD') }}</div> -->
                            <!-- <div class="center-content_con_right_list_con_One">
                                <div class="center-content_con_right_list_con_One_a">专利类型: {{ item.name }}</div>
                            </div> -->
                            <div class="center-content_con_right_list_cons_one">专利名称: {{ item.name }}</div>
                            <div class="center-content_con_right_list_cons_two">
                                申请人: {{ item.applicant.join(',') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="center-content_con_right">
                    <div class="center-content_con_right_top">
                        <!-- <div class="center-content_con_right_top_content" @click="show = true">
                            <img
                                src="../assets/home_icon_gongjifabu@3x (1).png"
                                alt=""
                                class="center-content_con_right_top_content_img"
                            />
                            <div class="center-content_con_right_top_content_title">供给发布</div>
                        </div> -->
                        <div class="center-content_con_right_top_content" @click="shows = true">
                            <img
                                src="../assets/home_icon_xuqiufabu@3x (2).png"
                                alt=""
                                class="center-content_con_right_top_content_img"
                            />
                            <div class="center-content_con_right_top_content_title">需求发布</div>
                        </div>
                    </div>
                    <div class="center-content_con_right_list">
                        <div
                            class="center-content_con_right_list_con"
                            v-for="item in demandPatent"
                            :key="item.id"
                            @click="demandPatents(item.id)"
                        >
                            <!-- <div style="margin-right: 20px">{{ formatTime(item.createdAt, 'YYYY.MM.DD') }}</div> -->
                            <!-- <div class="center-content_con_right_list_con_One">
                                <div class="center-content_con_right_list_con_One_a">专利类型: {{ item.name }}</div>
                                <div class="center-content_con_right_list_con_One_b">专利内容: {{ item.digest }}</div>
                            </div>
                            <div class="center-content_con_right_list_con_Two">成果所属人: {{ item.owner }}</div> -->
                            <div class="center-content_con_right_list_cons_one">专利名称: {{ item.name }}</div>
                            <div class="center-content_con_right_list_cons_two">需求描述: {{ item.description }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="service"> -->
            <!-- <div class="service-btn">
                    <div class="btn-item">
                        <el-button type="primary" @click="connectKefu">
                            <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                            咨询客服
                        </el-button>
                        <el-button @click="postMessage" type="primary"
                            ><i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言</el-button
                        >
                    </div>
                </div> -->

            <!-- <div class="service-list">
                    <service-box
                        v-for="(item, index) in services"
                        :key="index"
                        :info="item"
                        :index="index"
                    ></service-box>
                </div>
            </div> -->

            <!-- <resources></resources> -->

            <!-- <div class="link">
                <div class="link-name">
                    <div class="name" :class="{ active: partnerType === 'CLIENT' }" @mouseover="getPartner('CLIENT')">
                        客户
                    </div>
                    <div class="name" :class="{ active: partnerType === 'LINK' }" @mouseover="getPartner('LINK')">
                        链接
                    </div>
                </div>
                <div class="link-list">
                    <swiper class="link-swiper" ref="swiper" :options="linkOptions">
                        <swiper-slide v-for="item in partners" :key="item.id">
                            <a
                                class="link-img"
                                :href="item.url || 'http://zhirongip.izouma.com/web'"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img :src="item.img" alt=""
                            /></a>
                        </swiper-slide>
                    </swiper>
                </div>
            </div> -->
        </div>
        <div class="center-content">
            <divider-title>
                <template #prim>知产运营服务</template>
                <template #sub>IP Serve</template>
            </divider-title>
            <div class="service">
                <div class="service-list">
                    <service-box
                        v-for="(item, index) in services"
                        :key="index"
                        :info="item"
                        :index="index"
                    ></service-box>
                </div>
            </div>
        </div>
        <el-dialog title="发布供给信息" :visible.sync="show" center>
            <el-form :rules="rules" label-width="70px" hide-required-asterisk>
                <el-form-item prop="patent" label="供给类别">
                    <el-select v-model="formData.patent" placeholder="请选择">
                        <el-option v-for="item in parent" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="btns">
                <!-- <el-button type="info" size="small" @click="show = false">返回</el-button> -->
                <el-button type="primary" size="small" @click="Onsave">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="发布需求信息" :visible.sync="shows" center>
            <el-form :rules="rules" label-width="70px" hide-required-asterisk>
                <el-form-item prop="patent" label="需求类别">
                    <el-select v-model="formData.patent" placeholder="请选择">
                        <el-option v-for="item in demand" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="btns">
                <!-- <el-button type="info" size="small" @click="shows = false">返回</el-button> -->
                <el-button type="primary" size="small" @click="Onsaves">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Banner from '../components/Banner.vue';
import DividerTitle from '../components/DividerTitle.vue';
import Resources from '../components/home/Resources.vue';
import ServiceBox from '../components/home/ServiceBox.vue';
import comEvent from '../mixins/comEvent';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
export default {
    components: { DividerTitle, ServiceBox, Resources, Banner, Swiper, SwiperSlide },
    name: 'Home',
    mixins: [comEvent],
    data() {
        return {
            activeIndex: 0,
            autoplay: true,
            show: false,
            shows: false,
            typeSettingId: '',
            messageDisplayList: [],
            rules: {
                patent: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            newsList: [],
            newsListIndex: [],
            newsLists: [],
            supplys: [],
            active: '平台动态',
            tabs: ['平台动态', '通知公告', '知产百科'],
            actives: '知识产权',
            tabss: ['知识产权', '技术', '产品', '企业', '专家'],
            demands: [],
            formData: {},
            activeName: 'first',
            services: [
                {
                    name: '信息服务',
                    icon: 'png-xinxifuwu@3x',
                    content: '提供军民二元知识产权获权、管理、运用、保护等全生命周期的实务代理咨询服务',
                    path: '/agent?navigationId=95'
                },
                {
                    name: '咨询服务',
                    icon: 'png-zixunfuwu@3x',
                    content: '提供面向知识产权、军民融合、科技创新、新兴产业类政策性项目的申报、实施等全流程辅导',
                    path: '/agent?navigationId=101'
                },
                {
                    name: '转化运用服务',
                    icon: 'png-zhuanhuayunyongfuwu@3x',
                    content:
                        '为中小微企业、创新创业团队提供办公空间、资本对接、企业策划、财税法务等低成本、高效率的一站式服务',
                    path: '/agent?navigationId=1014'
                },
                {
                    name: '法律服务',
                    icon: 'png-falvfuwu@3x (1)',
                    content:
                        '为地方政府、军事单位、产业部门、军民融合企业提供“知识产权+军民融合”规划编制、信息分析、资产运营、政策培训…',
                    path: '/agent?navigationId=111'
                },
                {
                    name: '培训服务',
                    icon: 'png-peixunfuwu@3x (1)',
                    content: '为民参军企业提供军工资质、通用资质、军用市场准入等认证/认可的标准化建设辅导服务',
                    path: '/agent?navigationId=127'
                }
            ],
            linkOptions: {
                slidesPerView: 'auto',
                spaceBetween: 30
            },
            partnerType: 'CLIENT',
            partners: [],
            supplyPatent: [],
            demandPatent: []
        };
    },
    computed: {
        // newsListIndex() {
        //     return [...this.newsList].filter((item, index) => {
        //         return index < 4;
        //     });
        // },
        // newsType() {
        //     return this.tabs == '平台动态' ? 324 : 325;
        // },
        parent() {
            return [...this.supplys].filter((item, index) => {
                return index < 6;
            });
        },
        demand() {
            return [...this.demands].filter((item, index) => {
                return index < 7;
            });
        }
    },
    created() {
        this.$http
            .post(
                '/policyLaw/all',
                { query: { category: 'NEWS', typeSettingId: 324 }, sort: 'issuedAt,desc' },
                { body: 'json' }
            )
            .then(res => {
                this.newsList = res.content;
                this.newsListIndex = res.content.filter((item, index) => {
                    return index < 4;
                });
            });
        this.$http
            .post(
                '/intellectualProperty/all',
                { query: { category: 'IntellectualProperty', del: false }, sort: 'id,desc' },
                { body: 'json' }
            )
            .then(res => {
                this.newsLists = res.content;
            });
        this.$http.get('/technologyProduct/top').then(res => {
            // this.messageDisplayList = res;
            this.messageDisplayList = res;
        });
        this.$http
            .post(
                '/patent/all',
                { query: { caseType: 'GENERAL', del: false, status: 'PASS' }, sort: 'id,desc' },
                { body: 'json' }
            )
            .then(res => {
                this.supplyPatent = res.content;
            });
        this.$http
            .post(
                '/patentDemand/all',
                { query: { caseType: 'GENERAL', del: false, status: 'PASS' }, sort: 'id,desc' },
                { body: 'json' }
            )
            .then(res => {
                this.demandPatent = res.content;
            });
        this.getPartner('CLIENT');
        this.$http.post('/navigation/supply').then(res => {
            this.supplys = res;
        });
        this.$http.post('/navigation/demand').then(res => {
            this.demands = res;
        });
    },
    methods: {
        handleClick(item) {
            this.active = item;
            // let name = item == '平台动态' ? 324 : 325;
            if (item === '平台动态') {
                this.typeSettingId = 324;
            } else if (item === '通知公告') {
                this.typeSettingId = 325;
            } else {
                this.typeSettingId = 3240;
            }
            this.$http
                .post(
                    '/policyLaw/all',
                    { query: { category: 'NEWS', typeSettingId: this.typeSettingId }, sort: 'issuedAt,desc' },
                    { body: 'json' }
                )
                .then(res => {
                    this.newsList = res.content;
                });
        },
        handleClicks(item) {
            this.actives = item;
            // const typeSettingId = name;
            if (item === '知识产权') {
                this.$http
                    .post(
                        '/intellectualProperty/all',
                        { query: { category: 'IntellectualProperty', del: false }, sort: 'id,desc' },
                        { body: 'json' }
                    )
                    .then(res => {
                        this.newsLists = res.content;
                    });
            } else if (item === '技术') {
                this.$http
                    .post(
                        '/digital/all',
                        { query: { type: 'TECHNOLOGY', del: false }, sort: 'createdAt,desc' },
                        // type: "PRODUCT"
                        { body: 'json' }
                    )
                    .then(res => {
                        this.newsLists = res.content;
                    });
            } else if (item === '产品') {
                this.$http
                    .post(
                        '/digital/all',
                        { query: { type: 'PRODUCT', del: false }, sort: 'createdAt,desc' },
                        { body: 'json' }
                    )
                    .then(res => {
                        this.newsLists = res.content;
                    });
            } else if (item === '企业') {
                this.$http
                    .post(
                        '/company/all',
                        { query: { type: 'COMPANY', del: false }, sort: 'createdAt,desc' },
                        { body: 'json' }
                    )
                    .then(res => {
                        this.newsLists = res.content;
                    });
            } else if (item === '专家') {
                this.$http
                    .post(
                        '/expert/all',
                        { query: { affiliation: '', del: false, isExpert: true }, sort: 'createdAt,desc' },
                        { body: 'json' }
                    )
                    .then(res => {
                        this.newsLists = res.content;
                    });
            }
            // this.$http
            //     .post('/policyLaw/all', { query: { category: 'NEWS', typeSettingId } }, { body: 'json' })
            //     .then(res => {
            //         this.newsList = res.content;
            //     });
        },
        toDetails(id) {
            if (this.actives === '知识产权') {
                this.$router.push({ name: 'intellectualPropertyDetail', query: { id: id } });
            } else if (this.actives === '技术') {
                this.$router.push({ name: 'digitalDetail', query: { id: id } });
            } else if (this.actives === '产品') {
                this.$router.push({ name: 'digitalDetail', query: { id: id } });
            } else if (this.actives === '企业') {
                this.$router.push({ name: 'digitalDetails', query: { id: id } });
            } else if (this.actives === '专家') {
                this.$router.push({ name: 'expertDetail', query: { id: id } });
            }
        },
        Onsave() {
            if (!this.formData.patent) {
                this.$message.error('请选择类别');
                return;
            }
            this.$store.commit('updateShowAdd', true);
            if (this.formData.patent == 157) {
                this.$router.push('/patent?caseType=DECLASSIFICATION');
            } else if (this.formData.patent == 158) {
                this.$router.push('/patent?caseType=GENERAL');
            } else if (this.formData.patent == 159) {
                this.$router.push('/logo');
            } else if (this.formData.patent == 160) {
                this.$router.push('/copyright?type=COPY');
            } else if (this.formData.patent == 161) {
                this.$router.push('/copyright?type=SOFT');
            } else if (this.formData.patent == 162) {
                this.$router.push('/technologyProduct?tpType=TECHNOLOGY');
            } else if (this.formData.patent == 163) {
                this.$router.push('/technologyProduct?tpType=PRODUCT');
            }
        },
        Onsaves() {
            if (!this.formData.patent) {
                this.$message.error('请选择类别');
                return;
            }
            this.$store.commit('updateShowAdd', true);
            if (this.formData.patent == 167) {
                this.$router.push('/patentDemand?caseType=DECLASSIFICATION');
            } else if (this.formData.patent == 168) {
                this.$router.push('/patentDemand?caseType=GENERAL');
            } else if (this.formData.patent == 169) {
                this.$router.push('/logoDemand');
            } else if (this.formData.patent == 170) {
                this.$router.push('/copyrightDemand?type=COPY');
            } else if (this.formData.patent == 171) {
                this.$router.push('/copyrightDemand?type=SOFT');
            } else if (this.formData.patent == 172) {
                this.$router.push('/techProductDemand?tpType=TECHNOLOGY');
            } else if (this.formData.patent == 173) {
                this.$router.push('/techProductDemand?tpType=PRODUCT');
            }
        },
        supplyPatents(id) {
            this.$router.push({ name: 'patentDetail', query: { id: id } });
        },
        demandPatents(id) {
            this.$router.push({ name: 'patentDemandDetail', query: { id: id } });
        },
        changeCarousl() {
            this.activeIndex = this.$refs.carousel.activeIndex;
        },
        changeIndex(index) {
            this.$refs.carousel.setActiveItem(index);
        },
        getPartner(type) {
            this.partnerType = type;
            this.$http
                .post(
                    '/partner/all',
                    {
                        query: {
                            type: this.partnerType
                        }
                    },
                    {
                        body: 'json'
                    }
                )
                .then(res => {
                    this.partners = res.content;
                    this.$nextTick(() => {
                        this.$refs.swiper.$swiper.update();
                    });
                });
        },
        moreQualifications() {
            if (this.active === '平台动态') {
                this.$router.push('/newsList?flag=1');
            } else if (this.active === '通知公告') {
                this.$router.push('/newsList?flag=2');
            } else if (this.active === '知产百科') {
                this.$router.push('/newsList?flag=3');
            }
        },
        moreQualificationsOne() {
            this.$router.push('/newsList?flag=1');
        },
        moreQualificationsTwo() {
            console.log(this.actives);
            if (this.actives === '知识产权') {
                this.$router.push('/intellectualProperty');
            } else if (this.actives === '技术') {
                this.$router.push('/digital?type=TECHNOLOGY');
            } else if (this.actives === '产品') {
                this.$router.push('/digital?type=PRODUCT');
            } else if (this.actives === '企业') {
                this.$router.push('/digital?type=COMPANY');
            } else if (this.actives === '专家') {
                this.$router.push('/expert');
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.meta.needHistory) {
            this.$store.commit('setKeeps', ['Home']);
        } else {
            this.$store.commit('setKeeps', []);
        }
        this.$nextTick(() => {
            next();
        });
    }
};
</script>

<style lang="less" scoped>
.home {
    background-color: #fff;
    padding: 0 0 80px;
}
.btns {
    padding: 50px 30px 0;
}
/deep/ .el-form {
    // padding-left: 35%;
}
/deep/ .el-dialog {
    width: 760px;

    .el-form {
        width: 390px;
        margin: 0 auto;
    }

    .el-form-item {
        width: 100%;
        display: block !important;
        .el-select {
            width: 100%;
        }
        .el-input {
            width: 100%;
        }
    }

    .btns {
        padding-top: 30px;
    }
}
.news {
    margin-bottom: 100px;
    .flex();
    justify-content: space-between;
    .carousel {
        position: relative;
    }

    .el-carousel__item {
        background-color: #99a9bf;
    }

    /deep/ .el-carousel__indicators {
        display: none;
    }

    .indicators {
        position: absolute;
        right: 12px;
        bottom: 7px;
        z-index: 2;
        span {
            width: 22px;
            height: 22px;
            background: rgba(0, 0, 0, 0.46);
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            line-height: 22px;
            display: inline-block;
            cursor: pointer;

            &.active {
                background-color: #009e40;
            }
        }
    }
}
.center-content_con {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}
.center-content_con_left {
    .news-item {
        width: 793px;
    }
}
.center-content_con_right {
    width: 48%;
    // width: 387px;
    height: 383px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #f5f7fa;
    padding: 30px 30px 0px;
    box-sizing: border-box;
    overflow: hidden;
    .center-content_con_right_top {
        display: flex;
        justify-content: center;
        // margin-left: 100px;
        // margin: 0px 300px;
        margin-bottom: 24px;
        .center-content_con_right_top_content {
            cursor: pointer;
            width: 154px;
            height: 120px;
            background: #f1fffc;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // margin-right: 100px;
            .center-content_con_right_top_content_img {
                width: 54px;
                height: 54px;
                margin-bottom: 8px;
            }
            .center-content_con_right_top_content_title {
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: bold;
                color: #01a041;
            }
        }
    }
    .center-content_con_right_list {
        height: 213px;
        overflow-y: auto;
        padding-bottom: 20px;
    }
    .center-content_con_right_list_con {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        .center-content_con_right_list_cons_one {
            flex: 2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 30px;
        }
        .center-content_con_right_list_cons_two {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .center-content_con_right_list_con_One {
            flex: 3;
            display: flex;
            justify-content: space-between;
            .center-content_con_right_list_con_One_a {
                // flex: 1;
                width: 300px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .center-content_con_right_list_con_One_b {
                // flex: 2;
                width: 600px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0px 30px;
            }
        }
        .center-content_con_right_list_con_Two {
            flex: 1;
            // flex: 2;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: right;
            // margin: 0px 30px;
        }
    }
}
.news-item {
    width: calc(50% - 10px);
    .swiper-itemText {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        font-size: 14px;
        padding-left: 10px;
        color: rgba(255, 255, 255, 0.6);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: rgba(0, 0, 0, 0.4);
        line-height: 36px;
    }
    .tabBox {
        .flex();
        .tabs {
            margin: 6px 38px 0 0;
            font-size: 16px;
            font-weight: 400;
            padding-bottom: 10px;
            color: #939599;
            cursor: pointer;
            line-height: 22px;
            &.active {
                color: #009f40;
                border-bottom: 1px solid #009e40;
            }
        }
        border-bottom: 1px solid #f2f3f5;
    }
}

.tab {
    height: 360px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px #f5f7fa;
    padding: 16px 16px 10px;
    box-sizing: border-box;
    position: relative;
    .flex-col();
    overflow: hidden;

    /deep/.el-tabs__nav-wrap {
        &::after {
            height: 1px;
            background-color: @bg;
        }

        .el-tabs__active-bar {
            height: 1px;
        }
        .el-tabs__item {
            font-size: 16px;
        }
    }

    /deep/.el-tabs__header {
        margin: 0 0 0;
    }
    .sub {
        color: #bcc1cc !important;
    }
    .more {
        position: absolute;
        // color: #ff8700;
        color: #939599;
        right: 16px;
        top: 26px;
        z-index: 20;
    }
}
.empty {
    position: absolute;
    top: 45%;
    left: 42%;
    font-size: 30px;
    color: #ccc;
}
.news-list {
    flex-grow: 1;
    overflow: auto;
    padding: 25px 5px 15px;
    .news-link {
        cursor: pointer;
        .news-link_con {
            display: flex;
            align-items: center;
            .news-link_img {
                width: 36px;
                height: 36px;
                margin-right: 10px;
            }
        }
        text-decoration: none;
        .flex();
        font-size: 14px;
        line-height: 20px;
        justify-content: space-between;
        .text1 {
            width: 410px;
            color: #000;
            margin-right: 20px;
            flex-grow: 1;
        }
        .text4 {
            width: 540px;
            color: #000;
            margin-right: 20px;
            flex-grow: 1;
        }
        .text2 {
            color: @info;
        }

        &:hover {
            .text1 {
                color: #01a041;
            }

            .text2 {
                color: #01a041;
            }
            .text4 {
                color: #01a041;
            }
        }
    }
    .news-link + .news-link {
        margin-top: 25px;
    }
}
.hot {
    padding: 0 0 50px;
}
.hot-btn {
    .flex();
    justify-content: space-between;
}

.btn-item {
    .el-button {
        border-radius: 0;
        font-size: 12px;

        /deep/ i {
            font-size: 22px;
            line-height: 42px;
            display: inline-block;
            vertical-align: sub;
        }
        line-height: 42px;
        padding: 0 20px;
    }
}

.service-btn {
    .flex();
    justify-content: flex-end;
}

.products {
    .flex();
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 0;

    .hot-product {
        width: 31%;

        &:nth-child(n + 4) {
            margin-top: 30px;
        }
    }
}

.ad {
    display: block;
    width: 100%;
    height: 420px;
    background-color: @bg;
    background-size: cover;
    background-position: center center;
}

.service-list {
    padding: 0px 0 115px;
    .flex();
    justify-content: space-between;
    .service-box {
        width: 17%;
    }
}

.link {
    .link-name {
        .flex();
    }
    .name {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        line-height: 22px;
        padding: 15px 30px 15px 0;
        &.active {
            color: @prim;
        }
        cursor: pointer;
    }
}

.symbol {
    font-size: 88px;
}
.products {
    .flex();
    flex-wrap: wrap;

    .product-col2 {
        .flex();
        width: calc(50% - 30px);
        border-radius: 2px;
        border: 1px solid rgba(56, 85, 142, 0.04);
        padding-right: 15px;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;

        img {
            width: 330px;
            height: 220px;
            display: block;
            margin-left: 15px;
            flex-shrink: 0;
        }
        .text {
            width: 210px;
            height: 300px;
            padding: 30px;
            .flex-col();
            justify-content: center;
            .text1 {
                margin-bottom: 20px;
            }
            .text2 {
                margin-bottom: 10px;
            }
        }
    }
    .text {
        background: #fbfbfb;
        .text1 {
            font-size: 18px;
            font-weight: bold;
            color: @warn;
            line-height: 24px;
        }
        .text2 {
            font-size: 14px;
            color: #515151;
            line-height: 24px;
        }
    }
    .product-col3 {
        width: 360px;
        padding-top: 12px;
        margin-top: 60px;
        border-radius: 3px;
        border: 1px solid rgba(56, 85, 142, 0.04);
        background: #ffffff;
        cursor: pointer;
        overflow: hidden;
        img {
            width: 330px;
            height: 268px;
            display: block;
            margin: 0 15px 12px;
        }
        .text {
            height: 118px;
            background: #fbfbfb;
            padding: 20px;
            box-sizing: border-box;
            .flex-col();
            align-items: center;

            .text2 {
                width: 250px;
                margin-top: 6px;
                text-align: center;
            }
        }
    }
}

.products > .product-col2,
.products > .product-col3 {
    &:hover {
        // background-color: mix(fade(@prim, 20%), #fff, 10%);
        border-color: fade(@warn, 80%);
        .text {
            background-color: #008a69;
            .text1 {
                color: #fff;
            }
            .text2 {
                color: #fff;
            }
        }
    }
}

.link-swiper {
    .link-img {
        img {
            height: 30px;
            width: auto;
            display: block;
        }
    }
    /deep/ .swiper-slide {
        width: auto;
    }
}
</style>
