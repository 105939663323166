<template>
    <router-link
        :to="{
            name: 'expertDetail',
            query: {
                id: info.id
            }
        }"
        class="manage"
        :class="{ 'manage-mini': size === 'mini' }"
    >
        <el-image :src="getImg(info.avatar)" fit="fill"></el-image>
        <div class="content">
            <div class="name">{{ info.name }}</div>
            <!-- <div class="tags">
                <el-tag size="mini" :key="index" v-for="(item, index) in info.fieldName" effect="plain">{{
                    item
                }}</el-tag>
                <el-tag size="mini" effect="plain">电子信息</el-tag>
            </div> -->
            <div class="go">
                专业领域：

                <span v-for="(item, index) in info.fieldName" :key="index">{{ item }}</span>
            </div>
            <div class="edit-btn" v-if="isEdit">
                <el-button type="primary" size="small" plain @click.prevent="edit">编辑</el-button>
                <el-button size="small" plain @click.prevent="del">删除</el-button>
            </div>
            <!-- <div v-else>
                <div class="btn" v-if="size !== 'mini'">
                    <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small" plain>
                        <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                        咨询客服
                    </el-button>
                    <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                        <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
                    </el-button>
                </div>
            </div> -->
            <!-- <div class="bottom">{{ formatTime(info.createdAt, 'YYYY.MM.DD') }}</div> -->
        </div>
    </router-link>
</template>

<script>
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'normal'
        }
    },
    mixins: [comEvent],
    methods: {
        edit() {
            this.$router.push({
                path: '/technicalEdit',
                query: {
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/serviceProvider/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.manage {
    width: 220px;
    height: 290px;
    background: #ffffff;
    border: 1px solid rgba(56, 85, 142, 0.04);
    padding: 20px 20px 20px 20px;
    .flex-col();
    align-items: stretch;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    margin: 10px 10px 10px 14px;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81,81,81,0.1);
        border: 1px solid #01A041;
    }
}

.el-image {
    width: 180px;
    height: 180px;
    flex-shrink: 0;
    border-radius: 100px;
}
.content {
    .flex-col();
    align-items: center;
    padding: 14px 0 10px;
}

.tags {
    max-width: 100%;
    flex-wrap: nowrap;
    .ellipsis();
    // .flex();
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    margin-top: 8px;
    color: @prim;
}
.edit-btn {
    .flex();
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
.name {
    font-size: 16px;
    font-weight: bold;
    color: #292c33;
    line-height: 26px;
}

.go {
    text-align: center;
    font-size: 14px;
    color: #878d99;
    line-height: 24px;
    margin-top: 6px;
    // height: 48px;
    .ellipsis();
    max-width: 100%;
}
.btn {
    .flex();
    padding: 10px 0;
}
.icon-button {
    height: 26px;
    padding: 0 6px;
    i {
        font-size: 20px;
    }

    /deep/ i {
        font-size: 18px;
    }
}
.bottom {
    font-size: 13px;
    color: #939599;
    line-height: 18px;
    padding-top: 8px;
    border-top: 1px solid @bg;
    margin-top: 5px;
    align-self: stretch;
    text-align: center;
}
.manage-mini {
    width: 204px;
    height: 233px;
    margin-left: -11px;
    margin-right: 30px;
    .el-image {
        width: 112px;
        height: 112px;
        margin: auto;
        display: block;
        margin-top: 5px;
    }
}
</style>
